// app/layout.tsx
"use client";

import { usePathname } from "next/navigation";
import { Inter } from "next/font/google";
import "./globals.css";
import { useState } from "react";

const inter = Inter({ subsets: ["latin"] });

import Sidebar from "../components/Sidebar";

// export const metadata: Metadata = {
//   title: "Create Next App",
//   description: "Generated by create next app",
// };

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  // Determine the current pathname
  const pathname = usePathname();
  // Check if we're in a /cast route
  const isCastRoute = pathname.startsWith("/cast");

  // Sidebar toggle state (only used when not in a cast route)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <html lang="en">
      <body className={`${inter.className} bg-gray-100`}>
        {isCastRoute ? (
          // For /cast routes, render just the children (i.e. the page + its nested layout)
          <>{children}</>
        ) : (
          // For all other routes, render the default layout with the sidebar and toggle button.
          <>
            <button
              onClick={toggleSidebar}
              className="toggle-button fixed top-5 left-5 z-20 p-2 bg-blue-500 text-white rounded"
            >
              Toggle Sidebar
            </button>
            <div className="flex">
              <Sidebar isOpen={isSidebarOpen} />
              <div
                className={`flex-1 p-4 transition-margin duration-300 ease-in-out ${
                  isSidebarOpen ? "ml-64" : "ml-0"
                }`}
              >
                {children}
              </div>
            </div>
          </>
        )}
      </body>
    </html>
  );
}
